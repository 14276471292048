import { ICreateAccountFormVaultLinkProps } from './VaultLink/Model'

// TODO - There are some commentaries that are future changes that are needed in the Form Model.
export enum ProductTypeEnum {
    Standard = 'standard',
    Pro = 'pro',
}

export enum ProfileTypeEnum {
    Business = 'business',
    Individual = 'individual',
}

export interface IProfile {
    accountName: string
    password: string
    confirmPassword: string
    productType?: ProductTypeEnum
    profileType?: ProfileTypeEnum
}

export class Profile implements IProfile {
    constructor(
        public productType?: ProductTypeEnum,
        public profileType?: ProfileTypeEnum,
        public accountName: string = '',
        public password: string = '',
        public confirmPassword: string = ''
    ) {
        if (this.productType == null) delete this.productType
        if (this.profileType == null) delete this.profileType
    }
}

export interface IAcknowledgement {
    agree: string
    signature: string
}

export class Acknowledgement implements IAcknowledgement {
    constructor(public agree: string = '', public signature: string = '') {}
}
export interface IBankAccount {
    accountNumber: string
    accountNumberConfirmed: string
    routing: string
    type: string | undefined
    // type?: string
    name: string | undefined
    // name?: string
}

export class BankAccount implements IBankAccount {
    constructor(public accountNumber: string = '', public accountNumberConfirmed: string = '', public routing: string = '', public type: string = '', public name: string = '') {}
}

export interface IAccounts {
    bankAccounts: IBankAccount[]
}

export class Accounts implements IAccounts {
    constructor(public bankAccounts: IBankAccount[] = []) {}
}

export interface IAddress {
    type: string | number | undefined
    // type?: string | number
    street: string
    apartment: string
    city: string
    state: string
    zip: string
    country: string | undefined
    // country?: string
}

export class Address implements IAddress {
    constructor(
        public type: string = '',
        public street: string = '',
        public apartment: string = '',
        public city: string = '',
        public state: string = '',
        public zip: string = '',
        public country: string = ''
    ) {}
}

export interface IPhone {
    type: string
    // type: string | number
    phoneNumber: string
}

export enum PhoneTypeEnum {
    Home = 'home',
    Personal = 'personal',
    Business = 'business',
}
export class Phone implements IPhone {
    constructor(public type: PhoneTypeEnum = PhoneTypeEnum.Home, public phoneNumber: string = '') {}
}

export interface IBusiness {
    name: string
    addresses: IAddress[]
    tin?: string
    phoneNumbers?: IPhone[]
}

export class Business implements IBusiness {
    constructor(public name: string = '', public addresses: Address[] = [], public tin?: string, public phoneNumbers?: IPhone[]) {
        if (this.phoneNumbers === undefined) delete this.phoneNumbers
        if (this.tin === undefined) delete this.tin
    }
}

export interface IPII {
    ssn: string
    birthdate: string
}

export class PII implements IPII {
    constructor(public ssn: string = '', public birthdate: string = '') {}
}

export enum PersonTypeEnum {
    Admin = 'admin',
}

export enum UBOTypeEnum {
    ubo = 'ubo',
    Contact = 'contact',
    UBO = 'UBO',
}
export interface IPerson {
    // TODO - Should separate IPerson interface in two new interfaces (IPerson and IUBO). Some properties are different in each case
    type: PersonTypeEnum | UBOTypeEnum | number | undefined
    // type?: PersonTypeEnum | UBOTypeEnum | number
    email: string
    firstName: string
    lastName: string
    pii: IPII // Optional for UBO
    addresses: IAddress[] // Optional for UBO
    phoneNumbers: IPhone[]
    deviceSessionId: string | undefined
    // deviceSessionId?: string
    primaryContact: boolean | undefined
    // primaryContact?: boolean
    title: string | undefined
    // title?: string
}

export class Person implements IPerson {
    constructor(
        public type: PersonTypeEnum | UBOTypeEnum | number | undefined = undefined,
        public email: string = '',
        public firstName: string = '',
        public lastName: string = '',
        public pii: IPII = new PII(),
        public addresses: IAddress[] = [],
        public phoneNumbers: IPhone[] = [],
        public deviceSessionId: string = '',
        public primaryContact: boolean = true,
        public title: string = ''
    ) {}
}

export interface IQuestion {
    section: number
    id: number
    response: string | string[]
}

export class Question implements IQuestion {
    constructor(public section: number, public id: number, public response: string | string[]) {}
}

export interface ICreateAccountOptions {
    allowEdition: boolean
    nextTab: () => void
}

export interface ICreateAccountProps {
    profile: IProfile
    accounts: IAccounts
    // accounts?: IAccounts
    people: IPerson[]
    business: IBusiness | undefined
    // business?: IBusiness
    partnerReferral: string | undefined
    // partnerReferral?: string
    digitalAssetsDetails: IQuestion[] | undefined
    // digitalAssetsDetails?: IQuestion[]
    wallets: IQuestion[][] | undefined
    // wallets?: IQuestion[][]
    acknowledgement?: IAcknowledgement
}

export interface ICreateAccountPropsBase extends ICreateAccountOptions, ICreateAccountProps {}

export interface IGeneralInformationCreateAccountProps extends ICreateAccountPropsBase, ICreateAccountFormVaultLinkProps {}
