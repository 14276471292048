import { useState, createContext, useContext, useCallback } from 'react'
import { useConfig } from '..'
import { UploadFileDataModel } from '../../clients/api/uploadDocument/model'
import { UploadDocument } from '../../clients/api/uploadDocument/api'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UploadDocumentProvider = (props: any): JSX.Element => {
    const { envConfig } = useConfig()
    const [uploadingDocument, setUploadingDocument] = useState(false)
    const [initialized, setInitialized] = useState(false)

    const baseUrl = envConfig.DocumentAPI.BaseUrl
    const apiKey = envConfig.DocumentAPI.ApiKey
    const requestTimeout = envConfig.DocumentAPI.requestTimeout

    if (!initialized && baseUrl !== '' && apiKey !== '') {
        UploadDocument.Start(baseUrl, apiKey, requestTimeout)
        setInitialized(true)
    }

    const uploadDocumentFile = useCallback(async (req: UploadFileDataModel): Promise<void> => {
        try {
            setUploadingDocument(true)
            await UploadDocument.Upload(req)
        } finally {
            setUploadingDocument(false)
        }
    }, [])

    return <UploadDocumentContext.Provider value={{ uploadingDocument, uploadDocumentFile }} {...props} />
}

const UploadDocumentContext = createContext({
    uploadingDocument: false,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useUploadDocument = (): any => useContext(UploadDocumentContext)

export { UploadDocumentProvider, useUploadDocument }
