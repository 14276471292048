import axios, { AxiosInstance } from 'axios'
import { UploadFileDataModel } from './model'
import { EmptyResponse } from '../auth/model'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class UploadDocument {
    static axiosInstance: AxiosInstance

    static Start(baseUrl: string, apiKey: string, requestTimeout: number): void {
        UploadDocument.axiosInstance = axios.create({
            baseURL: baseUrl,
            timeout: requestTimeout,
        })

        // Request Interceptor
        UploadDocument.axiosInstance.interceptors.request.use(function (request) {
            request.headers.apikey = apiKey
            request.headers['Content-Type'] = 'multipart/form-data'
            return request
        })
    }

    static async postAsync<TReq, TResp>(endpoint: string, req: TReq): Promise<TResp> {
        const response = await UploadDocument.axiosInstance.post(endpoint, { ...req })
        return response.data
    }

    static async Upload(req: UploadFileDataModel): Promise<EmptyResponse> {
        return await UploadDocument.postAsync<UploadFileDataModel, EmptyResponse>('G2Z1N9EMFOKV710KFE9YE89O51YAVZS0MLWVMQC4WJWPD184VMSQBAOP7VKK', req)
    }
}
