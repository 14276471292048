import { useCallback, useEffect, useState } from 'react'
import { useAuth, useConfig } from '../../../contexts'
import notify from 'devextreme/ui/notify'
import QRCode from 'react-qr-code'
import Form, { Item, ButtonItem, ButtonOptions } from 'devextreme-react/form'
import { useNavigate } from 'react-router-dom'
import { ErrorMessage, Loading, SingleCard } from '../../common'
import './RegisterForm.scss'
import '../form.scss'
import { ChallengeEnum } from '../../../clients/api/auth'
import { HttpErrorCodes } from '../../../clients/api/auth/model'

const TwoFactorAuthRegisterForm = (): JSX.Element => {
    const [errorMessage, setErrorMessage] = useState('')

    const navigate = useNavigate()

    const { setup2FA, username, loading } = useAuth()
    const { firm, commonConfig } = useConfig()

    const customContent = commonConfig.Content.TFARegister

    const [secret2FA, setSecret2FA] = useState('')

    const getSecret2FA = useCallback(async () => {
        try {
            setErrorMessage('')
            const setup2FAResponse = await setup2FA()
            if (setup2FAResponse == null || setup2FAResponse.code !== HttpErrorCodes.OK) {
                setErrorMessage(`2FA Register Error: ${setup2FAResponse?.message}`)
                return
            }
            const otpAuthPath = `otpauth://totp/${firm}:${username}?secret=${setup2FAResponse.secret}`
            setSecret2FA(otpAuthPath)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            setErrorMessage('2FA Register Error: Server Error - Missing data to register the Two Factor Authentication (TOTP App)')
            const msg = error.response?.data.error == null ? 'Two Factor Authentication Error' : error.response.data.error.message
            notify(msg, 'error', 3000)
        }
    }, [firm, setup2FA, username])

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        if (secret2FA === '') getSecret2FA()
    }, [getSecret2FA, secret2FA, setup2FA])

    const onVerifyCodeClick = useCallback(() => {
        navigate('/two-factor-auth-verify', { state: { isRegistered: ChallengeEnum.MFAUnregisteredLM } })
    }, [navigate])

    return (
        <SingleCard {...customContent}>
            <>
                {loading && <Loading />}

                {!loading && errorMessage !== '' && <ErrorMessage message={errorMessage} />}

                {!loading && errorMessage === '' && (
                    <Form>
                        <Item>
                            <div className="TwoFA-QR">
                                <QRCode value={secret2FA} />
                            </div>
                        </Item>
                        {errorMessage !== '' ? (
                            <Item>
                                <ErrorMessage message={errorMessage} />
                            </Item>
                        ) : null}
                        <ButtonItem cssClass={'primary-button'}>
                            <ButtonOptions text={'Verify Code'} type={'default'} width={'100%'} onClick={onVerifyCodeClick} />
                        </ButtonItem>
                    </Form>
                )}
            </>
        </SingleCard>
    )
}

export default TwoFactorAuthRegisterForm
