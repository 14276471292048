import { useCallback, useState } from 'react'
import Form, { TabbedItem, TabPanelOptions, Tab, GroupItem, ButtonOptions, ButtonItem, Item } from 'devextreme-react/form'
import '../../Styles.scss'
import '../../../form.scss'
import { ICreateAccountFormVaultLinkProps } from '../Model'
import notify from 'devextreme/ui/notify'
import GeneralInformationForm from '../../tabs/GeneralInformationTab'
import PersonalDetailsForm from '../../tabs/PersonalDetailsTab'
import DigitalAssetBackgroundForm from '../../tabs/DigitalAssetBackgroundTab'
import AnticipatedActivityForm from '../../tabs/AnticipatedActivityTab'
import WalletIntakeForm from '../../tabs/WalletIntakeTab'
import { useAuth } from '../../../../../contexts'
import { useNavigate } from 'react-router'
import ExchangeWalletsForm from '../../tabs/ExchangeWalletsTab'
import AcknowledgementForm from '../../tabs/AcknowledgementTab'
import { ProductTypeEnum, ProfileTypeEnum } from '../../Model'
import { ErrorMessage, Loading } from '../../../../common'

const CreateAccountFormIndividualProVaultLink = (props: ICreateAccountFormVaultLinkProps): JSX.Element => {
    const navigate = useNavigate()
    const { createAccount, loading } = useAuth()
    const [currentTabIndex, setCurrentTabIndex] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')
    const tabIndexMax = 5
    const formData = props.data

    const nextTab = useCallback((tabIndexParam: number): void => {
        const nextTabValue = tabIndexParam + 1
        setCurrentTabIndex(nextTabValue)
    }, [])

    const lastTab = useCallback((): void => setCurrentTabIndex(tabIndexMax), [])

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (e: any): Promise<void> => {
            try {
                setErrorMessage('')
                formData.business = undefined
                formData.partnerReferral = undefined
                const response = await createAccount(formData)
                if (response.message) {
                    notify(response.message, 'error', 2500)
                    const cleanedMessage = response.message.replace(/"/g, '')
                    setErrorMessage(cleanedMessage)
                    return
                }
                notify(
                    'Your application has been received. Please allow 1-2 business days for your information to be reviewed by our compliance team. An email will be sent with a decision and information on next steps.',
                    'success',
                    2500
                )
                navigate('/')
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                const msg = error.response?.data.error == null ? 'Create Account Error' : error.response.data.error.message
                notify(msg, 'error', 2000)
            }
        },
        [createAccount, formData, navigate]
    )

    const onTabChanged = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            if (e.itemIndex !== currentTabIndex) setCurrentTabIndex(e.itemIndex)
        },
        [currentTabIndex]
    )

    const onProfileTypeValueChanged = useCallback(
        (newProfileType: ProfileTypeEnum): void => {
            props.onProfileTypeChanged(newProfileType)
        },
        [props]
    )

    const onProductTypeValueChanged = useCallback(
        (newProductType: ProductTypeEnum): void => {
            props.onProductTypeChanged(newProductType)
        },
        [props]
    )

    return (
        <>
            {currentTabIndex <= tabIndexMax ? (
                <Form formData={formData} className="register">
                    <TabbedItem cssClass="tabStyle">
                        <TabPanelOptions deferRendering={false} width={'100%'} selectedIndex={currentTabIndex} onTitleClick={onTabChanged} />
                        <Tab title={'General Information'}>
                            <GeneralInformationForm
                                {...formData}
                                onProfileTypeChanged={onProfileTypeValueChanged}
                                onProductTypeChanged={onProductTypeValueChanged}
                                allowEdition={true}
                                nextTab={() => nextTab(currentTabIndex)}
                                data={formData}
                            />
                        </Tab>
                        <Tab title={'Personal Details'} disabled={currentTabIndex < 1}>
                            <PersonalDetailsForm {...formData} allowEdition={true} nextTab={() => nextTab(currentTabIndex)} />
                        </Tab>
                        <Tab title={'Digital Asset Background & Information'} disabled={currentTabIndex < 2}>
                            <DigitalAssetBackgroundForm {...formData} allowEdition={true} nextTab={() => nextTab(currentTabIndex)} />
                        </Tab>
                        <Tab title={'Non-Exchange Wallets'} disabled={currentTabIndex < 3}>
                            <WalletIntakeForm {...formData} allowEdition={true} nextTab={() => nextTab(currentTabIndex)} />
                        </Tab>
                        <Tab title={'Exchange Wallets'} disabled={currentTabIndex < 4}>
                            <ExchangeWalletsForm {...formData} allowEdition={true} nextTab={() => nextTab(currentTabIndex)} />
                        </Tab>
                        <Tab title={'Acknowledgement'} disabled={currentTabIndex < tabIndexMax}>
                            <AcknowledgementForm {...formData} allowEdition={true} nextTab={() => nextTab(currentTabIndex)} />
                        </Tab>
                    </TabbedItem>
                </Form>
            ) : (
                <Form formData={formData} className="register">
                    <Item editorType={'dxTextBox'} disabled={true} cssClass={'reviewDescription'}>
                        Please review the information below for accuracy. Select &apos;Register&apos; to submit your information for review or &apos;Back&apos; to update details
                        within any section.
                    </Item>
                    <GroupItem caption={'General Information'} cssClass="outline">
                        <GeneralInformationForm
                            {...formData}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onProfileTypeChanged={() => {}}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onProductTypeChanged={() => {}}
                            allowEdition={false}
                            data={formData}
                        />
                    </GroupItem>
                    <GroupItem caption={'Personal Details'} cssClass="outline">
                        <PersonalDetailsForm {...formData} allowEdition={false} />
                    </GroupItem>
                    <GroupItem caption={'Digital Asset Background & Information'} cssClass="outline">
                        <DigitalAssetBackgroundForm {...formData} allowEdition={false} />
                    </GroupItem>
                    <GroupItem caption={'Anticipated Activity'} cssClass="outline">
                        <AnticipatedActivityForm {...formData} allowEdition={false} />
                    </GroupItem>
                    <GroupItem caption={'Wallet Intake'} cssClass="outline">
                        <WalletIntakeForm {...formData} allowEdition={false} />
                    </GroupItem>
                    <GroupItem caption={'Exchange Wallets'} cssClass="outline">
                        <ExchangeWalletsForm {...formData} allowEdition={false} />
                    </GroupItem>
                    <GroupItem caption={'Acknowledgement'} cssClass="outline">
                        <AcknowledgementForm {...formData} allowEdition={false} />
                    </GroupItem>
                    <ButtonItem>
                        <ButtonOptions elementAttr={backButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true} onClick={lastTab}>
                            <span className="dx-button-text">Back</span>
                        </ButtonOptions>
                    </ButtonItem>
                    <ButtonItem>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true} onClick={onSubmit}>
                            <span className="dx-button-text">{loading === true ? <Loading /> : 'Register'}</span>
                        </ButtonOptions>
                    </ButtonItem>
                    {errorMessage !== '' ? (
                        <Item>
                            <ErrorMessage message={errorMessage} />
                        </Item>
                    ) : null}
                </Form>
            )}
        </>
    )
}

const submitButtonAttributes = { class: 'submit-button' }
const backButtonAttributes = { class: 'secondary-button' }

export default CreateAccountFormIndividualProVaultLink
