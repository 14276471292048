import axios from 'axios'
import { merge } from 'lodash'
import { useState, createContext, useContext, useEffect } from 'react'
import { CommonConfigContextModel, ConfigContextModel, ConfigStateEnum, EnvConfigModel } from './model'

const commonConfigContextEmpty = new CommonConfigContextModel()
const commonGeneralConfigContextEmpty = new CommonConfigContextModel()
const commonFirmConfigContextEmpty = new CommonConfigContextModel()
const envConfigContextEmpty = new EnvConfigModel()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConfigProvider = (props: any): JSX.Element => {
    const [loadingCommonConfig, setLoadingCommonConfig] = useState(ConfigStateEnum.Unloaded)
    const [commonConfig, setCommonConfig] = useState(commonConfigContextEmpty)
    const [loadingCommonGeneralConfig, setLoadingCommonGeneralConfig] = useState(ConfigStateEnum.Unloaded)
    const [commonGeneralConfig, setCommonGeneralConfig] = useState(commonGeneralConfigContextEmpty)
    const [loadingCommonFirmConfig, setLoadingCommonFirmConfig] = useState(ConfigStateEnum.Unloaded)
    const [commonFirmConfig, setCommonFirmConfig] = useState(commonFirmConfigContextEmpty)

    const [loadingEnvConfig, setLoadingEnvConfig] = useState(ConfigStateEnum.Unloaded)
    const [envConfig, setEnvConfig] = useState(envConfigContextEmpty)

    if (process.env.REACT_APP_FIRM === undefined || process.env.REACT_APP_ENV === undefined) throw new Error('Firm or Environment undefined')

    const firm = process.env.REACT_APP_FIRM.trim()
    const environment = process.env.REACT_APP_ENV.trim()
    switch (environment) {
        case 'LOCAL':
        case 'DEV':
        case 'UAT':
        case 'PROD':
            break
        default:
            throw new Error(`${environment} unsuported`)
    }

    const configBaseUrl = `../firms`
    const configFirmBaseUrl = `${configBaseUrl}/${firm}`
    const imageBaseUrl = `${configFirmBaseUrl}/images`
    const commonConfigJson = 'COMMON.config.json'

    useEffect(() => {
        /** Get the environment configuration */
        if (loadingEnvConfig === ConfigStateEnum.Unloaded) {
            setLoadingEnvConfig(ConfigStateEnum.Loading)
            getConfig<EnvConfigModel>(`${configFirmBaseUrl}/env/${environment}.config.json`, setEnvConfig, setLoadingEnvConfig)
        }
        /** Get the COMMON Config general */
        if (loadingCommonGeneralConfig === ConfigStateEnum.Unloaded) {
            setLoadingCommonGeneralConfig(ConfigStateEnum.Loading)
            getConfig<CommonConfigContextModel>(`${configBaseUrl}/${commonConfigJson}`, setCommonGeneralConfig, setLoadingCommonGeneralConfig)
        }
        /** Get the COMMON Config by firm to override the general */
        if (loadingCommonFirmConfig === ConfigStateEnum.Unloaded) {
            setLoadingCommonFirmConfig(ConfigStateEnum.Loading)
            getConfig<CommonConfigContextModel>(`${configFirmBaseUrl}/${commonConfigJson}`, setCommonFirmConfig, setLoadingCommonFirmConfig)
        }
        setLoadingCommonConfig(
            loadingCommonGeneralConfig === ConfigStateEnum.Unloaded || loadingCommonFirmConfig === ConfigStateEnum.Unloaded
                ? ConfigStateEnum.Unloaded
                : loadingCommonGeneralConfig === ConfigStateEnum.Loading || loadingCommonFirmConfig === ConfigStateEnum.Loading
                ? ConfigStateEnum.Loading
                : ConfigStateEnum.Loaded
        )
        /** Set the common config */
        if (loadingCommonConfig === ConfigStateEnum.Loaded) {
            /** Override the common config with the firm config */
            const configMerged = merge(commonGeneralConfig, commonFirmConfig)
            setCommonConfig(configMerged)
        }
    }, [
        commonGeneralConfig,
        commonFirmConfig,
        configBaseUrl,
        configFirmBaseUrl,
        environment,
        firm,
        loadingCommonGeneralConfig,
        loadingCommonFirmConfig,
        loadingCommonConfig,
        loadingEnvConfig,
        envConfig,
    ])

    function getConfig<Type>(url: string, setConfig: (config: Type) => void, setLoading: (configStateEnum: ConfigStateEnum) => void): void {
        axios
            .get(url)
            .then((response) => {
                setConfig(response.data)
            })
            .catch((err) => console.error(`Cannot get the configuration from '${url}', error: ${JSON.stringify(err)} `))
            .finally(() => {
                setLoading(ConfigStateEnum.Loaded)
            })
    }

    const configContextData = new ConfigContextModel(commonConfig, loadingCommonConfig, envConfig, loadingEnvConfig, firm, environment, imageBaseUrl)

    return <ConfigContext.Provider value={{ ...configContextData }} {...props} />
}

const configContextEmpty = new ConfigContextModel()
const ConfigContext = createContext(configContextEmpty)

const useConfig = (): ConfigContextModel => useContext(ConfigContext)

export { ConfigProvider, useConfig }
