import { SingleCardCustomizableData } from '../../common/single-card/singleCardModel'

export class NewPasswordFormCustomizableData extends SingleCardCustomizableData {
    constructor(public userInputText: string = 'User', public submitButtonText: string = 'Submit New Password') {
        super()
    }
}
export default class NewPasswordFormProps {
    constructor(public username: string = '', public passwordNew: string = '', public passwordNewConfirm: string = '') {}
}
