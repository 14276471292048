import Form, { ButtonItem, ButtonOptions, Item, GroupItem, PatternRule } from 'devextreme-react/form'
import { useCallback } from 'react'
import { ICreateAccountPropsBase, Person, Phone, PhoneTypeEnum, UBOTypeEnum } from '../Model'
import DataGrid, { Column, Editing, Paging, RequiredRule } from 'devextreme-react/data-grid'
import { Const } from '../../../../const'

const UBOForm = (props: ICreateAccountPropsBase): JSX.Element => {
    const formData = props
    const allowEdition: boolean = props.allowEdition
    const formDisabled = !allowEdition

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            e.preventDefault()
            if (ubos.length <= 0) return
            // formData.people.length = 2
            ubos.forEach((ubo) => {
                const previous = /\+1/g
                const matches = formData.people[0].phoneNumbers[0].phoneNumber.match(previous)
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (!matches) {
                    ubo.phoneNumber = '+1'.concat('', ubo.phoneNumber)
                }
                formData.people.push(
                    new Person(
                        UBOTypeEnum.UBO,
                        ubo.email,
                        ubo.firstName,
                        ubo.lastName,
                        undefined,
                        undefined,
                        [new Phone(PhoneTypeEnum.Business, ubo.phoneNumber)],
                        undefined,
                        undefined,
                        ubo.title
                    )
                )
            })
            props.nextTab()
        },
        [props, formData.people]
    )

    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData}>
                <GroupItem>
                    <Item>
                        <div className="policy-info">
                            An ultimate beneficial owner (UBO) would include any individual who (1) exercises substantial control over the reporting company and/or (2) owns or
                            controls at least 25% of the ownership interests of the reporting company. Any individual designated will be required to complete the Know Your Customer
                            (KYC) process before an account can be activated.
                        </div>
                    </Item>
                </GroupItem>
                <GroupItem>
                    <DataGrid dataSource={ubos} keyExpr="ID" showBorders={true} disabled={formDisabled}>
                        <Paging enabled={false} />
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            allowAdding={true}
                            allowDeleting={true}
                            // selectTextOnEditStart={this.state.selectTextOnEditStart}
                            // startEditAction={this.state.startEditAction}
                        />
                        <Column dataField={'firstName'} caption="First Name" editorOptions={uboNameEditorOptions}>
                            <RequiredRule />
                        </Column>
                        <Column dataField={'lastName'} caption="Last Name" editorOptions={uboLastNameEditorOptions}>
                            <RequiredRule />
                        </Column>
                        <Column dataField={'title'} caption="Title" width={125} editorOptions={uboTitleEditorOptions}>
                            <RequiredRule />
                        </Column>
                        <Column dataField={'email'} caption="Email" width={200} editorOptions={uboEmailEditorOptions}>
                            <RequiredRule />
                            <PatternRule message="Email is not valid" pattern={Const.EmailValidatorPattern} />
                        </Column>
                        <Column dataField={'phoneNumber'} caption="Phone" editorOptions={uboPhoneEditorOptions}>
                            <RequiredRule />
                        </Column>
                    </DataGrid>
                </GroupItem>
                {!formDisabled ? (
                    <ButtonItem>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">Continue</span>
                        </ButtonOptions>
                    </ButtonItem>
                ) : (
                    <></>
                )}
            </Form>
        </form>
    )
}
const uboPhoneEditorOptions = {
    stylingMode: 'outlined',
    mask: '+1 (X00) 000-0000',
    maskRules: {
        X: /[02-9]/,
    },
    inputAttr: {
        autoComplete: 'new-phone',
    },
}
const uboNameEditorOptions = {
    stylingMode: 'outlined',
    mode: 'text',
}
const uboLastNameEditorOptions = {
    stylingMode: 'outlined',
    mode: 'text',
}
const uboEmailEditorOptions = {
    stylingMode: 'outlined',
    mode: 'text',
}
const uboTitleEditorOptions = {
    stylingMode: 'outlined',
    mode: 'text',
}

class UBO {
    constructor(public ID: number, public email: string, public firstName: string, public lastName: string, public title: string, public phoneNumber: string) {}
}
const ubos: UBO[] = []
const submitButtonAttributes = { class: 'submit-button' }

export default UBOForm
