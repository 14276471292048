import { RefObject, memo } from 'react'
import FileUploader from 'devextreme-react/file-uploader'
import { ValueChangedEvent } from 'devextreme/ui/file_uploader'
import { ColumnEditCellTemplateData } from 'devextreme/ui/data_grid'
import { UploadFileDataModel } from '../../../clients/api/uploadDocument'
import { useUploadDocument } from '../../../contexts'
import { useCallbackWrapper } from '../../../utils/useCallbackWrapper'

interface FileUploaderPreviewProps {
    cellInfo: ColumnEditCellTemplateData
    fileUploaderRef: RefObject<FileUploader>
}

export const FileUploaderWithPreview = memo(({ cellInfo, fileUploaderRef }: FileUploaderPreviewProps) => {
    const { uploadDocumentFile } = useUploadDocument()
    const onValueChanged = useCallbackWrapper((e: ValueChangedEvent) => {
        // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
        const dataModel = new UploadFileDataModel(e.value, e.value![0].name)
        try {
            uploadDocumentFile(dataModel)
        } catch (error) {
            console.error(error)
        }
        cellInfo.setValue(dataModel.RequestID)
    })

    return (
        <>
            <FileUploader ref={fileUploaderRef} multiple={false} accept="image/*" uploadMode="instantly" selectButtonText="Choose File" onValueChanged={onValueChanged} />
        </>
    )
})
FileUploaderWithPreview.displayName = 'FilePreview'
