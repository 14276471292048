import ScrollView from 'devextreme-react/scroll-view'
import './singleCard.scss'
import { SingleCardProps } from './singleCardModel'
import { ReactSVG } from 'react-svg'
import { useConfig } from '../../../contexts'

const SingleCard = (props: SingleCardProps): JSX.Element => {
    const { imageBaseUrl } = useConfig()
    const className = 'with-footer single-card '.concat(props.className)
    const baseImagePath: string = imageBaseUrl
    const logoImg = `${baseImagePath}/app-logo.svg`
    return (
        <ScrollView height={'100%'} width={'100%'} className={className}>
            <div className={'dx-card content'}>
                <div className={'header'}>
                    <ReactSVG src={logoImg} className="app-logo" />
                    <div className={'title'}>{props.title}</div>
                    <div className={'description'}>{props.description}</div>
                </div>
                {props.children}
            </div>
        </ScrollView>
    )
}

export default SingleCard
