import { Loading } from '../components/common'
import { useConfig } from '../contexts'
import { ConfigStateEnum } from '../contexts/config/model'
import AllRoutes from './Routes'

const PublicContentBase = (): JSX.Element => {
    const { loadingCommonConfig } = useConfig()

    return <>{loadingCommonConfig === ConfigStateEnum.Loaded ? <AllRoutes /> : <Loading />}</>
}

export default PublicContentBase
