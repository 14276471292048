import { useConfig } from '../../../contexts'
import CreateAccountFormCognitoDev from './CognitoDev/Form'
import CreateAccountFormVaultLink from './VaultLink/Form'

const CreateAccountForm = (): JSX.Element => {
    const { firm } = useConfig()
    return (
        <div className="CreateAccountForm">
            {firm === 'VaultLink' ? <CreateAccountFormVaultLink /> : <></>}
            {firm === 'CognitoDev' ? <CreateAccountFormCognitoDev /> : <></>}
        </div>
    )
}

export default CreateAccountForm
