import Form, { Item, Label, RequiredRule, ButtonItem, ButtonOptions, GroupItem } from 'devextreme-react/form'
import { CheckBox } from 'devextreme-react/check-box'
import { useCallback, useState } from 'react'
import { LabelTemplate, ImageIcon } from '../../../common'
import { ICreateAccountPropsBase } from '../Model'

const DigitalAssetBackgroundForm = (props: ICreateAccountPropsBase): JSX.Element => {
    const formData = props
    const allowEdition: boolean = props.allowEdition
    const formDisabled = !allowEdition
    const [showDigitalAssets, setShowDigitalAssets] = useState(false)

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            e.preventDefault()
            props.nextTab()
        },
        [props]
    )
    const showDigitalAssetsChanged = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            setShowDigitalAssets(e.value)
        },
        []
    )

    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData}>
                <GroupItem>
                    <CheckBox value={showDigitalAssets} onValueChanged={showDigitalAssetsChanged} text="Have you ever owned any digital assets?" disabled={formDisabled} />
                </GroupItem>
                {(showDigitalAssets || formDisabled) && (
                    <GroupItem caption={'Digital Asset Profile'} cssClass="outline" colCount={2}>
                        <Item dataField={'digitalAssetsDetails[0].response'} editorType={'dxSelectBox'} editorOptions={netWorthEditorOptions} disabled={formDisabled}>
                            <RequiredRule message="Option is required" />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'digitalAssetsDetails[1].response'} editorType={'dxSelectBox'} editorOptions={owningEditorOptions} disabled={formDisabled}>
                            <RequiredRule message="option is required" />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'digitalAssetsDetails[2].response'} editorType={'dxSelectBox'} editorOptions={primaryEditorOptions} disabled={formDisabled}>
                            <RequiredRule message="Option is required" />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'digitalAssetsDetails[3].response'} editorType={'dxTagBox'} editorOptions={activitiesEditorOptions} disabled={formDisabled}>
                            <RequiredRule message="Option is required" />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'digitalAssetsDetails[4].response'} editorType={'dxSelectBox'} editorOptions={depositsPerMonthEditorOptions} disabled={formDisabled}>
                            <RequiredRule message="option is required" />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'digitalAssetsDetails[5].response'} editorType={'dxSelectBox'} editorOptions={depositsInUsdEditorOptions} disabled={formDisabled}>
                            <RequiredRule message="Option is required" />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'digitalAssetsDetails[6].response'} editorType={'dxTagBox'} editorOptions={sourceEditorOptions} disabled={formDisabled}>
                            <RequiredRule message="Option is required" />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'digitalAssetsDetails[7].response'} editorType={'dxSelectBox'} editorOptions={withdrawalsInUsdEditorOptions} disabled={formDisabled}>
                            <RequiredRule message="option is required" />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'digitalAssetsDetails[8].response'} editorType={'dxSelectBox'} editorOptions={withdrawalsPerMonthEditorOptions} disabled={formDisabled}>
                            <RequiredRule message="Option is required" />
                            <Label render={LabelTemplate} />
                        </Item>
                    </GroupItem>
                )}
                {!formDisabled && (
                    <ButtonItem>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">Continue</span>
                        </ButtonOptions>
                    </ButtonItem>
                )}
            </Form>
        </form>
    )
}

const netWorthEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Digital Asset Net Worth?',
    mode: 'text',
    dataSource: [
        { ID: '1', Name: '$0 - $10k' },
        { ID: '2', Name: '$10k - $1M' },
        { ID: '3', Name: '$1M - $5M' },
        { ID: '4', Name: '$5M - $30M' },
        { ID: '5', Name: '$30M - $500M' },
        { ID: '6', Name: '$500M+' },
    ],
    displayExpr: 'Name',
    valueExpr: 'ID',
    dropDownButtonRender: { ImageIcon },
    icon: false,
    id: 'netWorth',
}
const owningEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Experience Owning Digital Assets?',
    mode: 'text',
    dataSource: [
        { ID: '1', Name: '0 - 1 year' },
        { ID: '2', Name: '1 - 3 years' },
        { ID: '3', Name: '3 - 5 years' },
        { ID: '4', Name: '5+ years' },
    ],
    displayExpr: 'Name',
    valueExpr: 'ID',
    icon: false,
    id: 'owning',
}
const primaryEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Primary Use for Digital Assets?',
    mode: 'text',
    dataSource: [
        { ID: '1', Name: 'Investing / Holding' },
        { ID: '2', Name: 'Online Purchases' },
        { ID: '3', Name: 'Payments' },
        { ID: '4', Name: 'Trading (buy/sell)' },
        { ID: '5', Name: 'Other' },
    ],
    displayExpr: 'Name',
    valueExpr: 'ID',
    icon: false,
    id: 'primary',
}
const activitiesEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Have you ever engaged in any of the following activities using digital assets? *',
    mode: 'text',
    dataSource: [
        { ID: '1', Name: 'Coin Swap Service' },
        { ID: '2', Name: 'Crypto ATMs' },
        { ID: '3', Name: 'Decentralized Exchanges (DEXs)' },
        { ID: '4', Name: 'Decentralized Finance (DeFi)' },
        { ID: '5', Name: 'Gambling' },
        { ID: '6', Name: 'Mixers' },
        { ID: '7', Name: 'None of the above' },
    ],
    displayExpr: 'Name',
    valueExpr: 'ID',
    icon: false,
    id: 'activities',
    placeholder: 'Select all that apply...',
}
const depositsPerMonthEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Anticipated deposits per month valued in USD?',
    mode: 'text',
    dataSource: [
        { ID: '1', Name: 'Less than $100,000' },
        { ID: '2', Name: '$100,000 - $999,999' },
        { ID: '3', Name: '$1,000,000 - $10,000,000' },
        { ID: '4', Name: 'Greater than $10,000,000' },
    ],
    displayExpr: 'Name',
    valueExpr: 'ID',
    icon: false,
    id: 'deposits_month',
}
const depositsInUsdEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Anticipated number of deposits per month?',
    mode: 'text',
    dataSource: [
        { ID: '1', Name: 'Less than 25' },
        { ID: '2', Name: '25 - 100' },
        { ID: '3', Name: 'Greater than 100' },
    ],
    displayExpr: 'Name',
    valueExpr: 'ID',
    icon: false,
    id: 'deposits_usd',
}
const sourceEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Source of anticipated deposits?',
    mode: 'text',
    dataSource: [
        { ID: '1', Name: 'Credit/Loan' },
        { ID: '2', Name: 'Crypto Mining/Staking' },
        { ID: '3', Name: 'Gift' },
        { ID: '4', Name: 'Inheritance' },
        { ID: '5', Name: 'Initial Coin Offering (ICO)' },
        { ID: '6', Name: 'Investments' },
        { ID: '7', Name: 'Occupation' },
        { ID: '8', Name: 'Savings' },
    ],
    displayExpr: 'Name',
    valueExpr: 'ID',
    icon: false,
    id: 'source',
    placeholder: 'Select all that apply...',
}
const withdrawalsInUsdEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Anticipated number of withdrawals per month in USD?',
    mode: 'text',
    dataSource: [
        { ID: '1', Name: 'Less than $100,000' },
        { ID: '2', Name: '$100,000 - $999,999' },
        { ID: '3', Name: '$1,000,000 - $10,000,000' },
        { ID: '4', Name: 'Greater than $10,000,000' },
    ],
    displayExpr: 'Name',
    valueExpr: 'ID',
    icon: false,
    id: 'withdrawals_usd',
}
const withdrawalsPerMonthEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Anticipated number of withdrawals per month?',
    mode: 'text',
    dataSource: [
        { ID: '1', Name: 'Less than 25' },
        { ID: '2', Name: '25 - 100' },
        { ID: '3', Name: 'Greater than 100' },
    ],
    displayExpr: 'Name',
    valueExpr: 'ID',
    icon: false,
    id: 'withdrawals_month',
}
const submitButtonAttributes = { class: 'submit-button' }

export default DigitalAssetBackgroundForm
