import { useState, createContext, useContext, useCallback } from 'react'
import { Auth, AuthResponse, LoginRequest, ResetPasswordRequest, UsernameAuthBaseRequest, Verify2FARequest } from '../../clients/api/auth'
import { useConfig } from '..'
import { useLocalStorage } from 'usehooks-ts'
import { ICreateAccountPropsBase } from '../../components/forms/createAccountForm/Model'
import ForgotPasswordFormProps from '../../components/forms/forgotPasswordForm/Model'
import ResetPasswordFormProps from '../../components/forms/resetPasswordForm/Model'
import { ChallengeEnum, ChangePasswordRequest, EmptyResponse, HttpErrorCodes, NewPasswordRequest, Setup2FAResponse, UserResponse } from '../../clients/api/auth/model'
import ChangePasswordFormProps from '../../components/forms/changePasswordForm/Model'
import { ConfigStateEnum } from '../config/model'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthProvider = (props: any): JSX.Element => {
    const [initialized, setInitialized] = useState(false)
    const [userStored, setUserStored] = useLocalStorage('userStored', '')
    const [redirectToAppCount, setRedirectCount] = useLocalStorage('redirectToAppCount', 0)
    const [rememberMe, setRememberMeStored] = useLocalStorage('rememberMe', false)
    const [username, setUsername] = useState(userStored)
    const [loading, setLoading] = useState(true)

    const { loadingEnvConfig, envConfig } = useConfig()

    const signIn = useCallback(async (mail: string, password: string): Promise<AuthResponse> => {
        setLoading(true)
        const req = new LoginRequest(mail, password)
        try {
            const loginResponse: AuthResponse = await Auth.Login(req)
            if (loginResponse.code !== HttpErrorCodes.OK) {
                return loginResponse
            }

            const challenge = loginResponse.challenge
            if (challenge === undefined) {
                loginResponse.ChallengeCode = ChallengeEnum.MFANo
                return loginResponse
            }
            switch (challenge) {
                case 'MFA_SETUP_LM':
                    loginResponse.ChallengeCode = ChallengeEnum.MFAUnregisteredLM
                    break
                case 'SOFTWARE_TOKEN_MFA':
                    loginResponse.ChallengeCode = ChallengeEnum.MFARegistered
                    break
                case 'NEW_PASSWORD_REQUIRED':
                    loginResponse.ChallengeCode = ChallengeEnum.NewPassword
                    break
                case 'MFA_SETUP': // TODO: implement MFA_SETUP challenge
                default:
                    throw new Error('Unsupported challenge value')
            }
            return loginResponse
        } finally {
            setLoading(false)
        }
    }, [])

    const getUser = useCallback(async (username: string): Promise<UserResponse> => {
        setLoading(true)
        const req = new UsernameAuthBaseRequest(username)
        try {
            // eslint-disable-next-line @typescript-eslint/return-await
            return await Auth.GetUser(req)
        } finally {
            setLoading(false)
        }
    }, [])

    const newPassword = useCallback(async (username: string, passwordNew: string, confirmedPasswordNew: string): Promise<EmptyResponse> => {
        setLoading(true)
        const req = new NewPasswordRequest(username, passwordNew, confirmedPasswordNew)
        try {
            return await Auth.NewPassword(req)
        } finally {
            setLoading(false)
        }
    }, [])

    const setup2FA = useCallback(async (): Promise<Setup2FAResponse> => {
        setLoading(true)

        try {
            const req = new UsernameAuthBaseRequest(username)
            return await Auth.Setup2FA(req)
        } finally {
            setLoading(false)
        }
    }, [username])

    const verify2FA = useCallback(
        async (code: string): Promise<EmptyResponse> => {
            setLoading(true)

            try {
                const req = new Verify2FARequest(username, code)
                return await Auth.Verify2FA(req)
            } finally {
                setLoading(false)
            }
        },
        [username]
    )

    const verifyAfterLogin2FA = useCallback(
        async (code: string): Promise<EmptyResponse> => {
            setLoading(true)

            try {
                const req = new Verify2FARequest(username, code)
                return await Auth.VerifyAfterLogin2FA(req)
            } finally {
                setLoading(false)
            }
        },
        [username]
    )

    const createAccount = useCallback(async (accountData: ICreateAccountPropsBase): Promise<EmptyResponse> => {
        setLoading(true)

        try {
            const response = await Auth.Register(accountData)
            return response
        } finally {
            setLoading(false)
        }
    }, [])

    const resetPassword = useCallback(async (resetPasswordData: ResetPasswordFormProps): Promise<EmptyResponse> => {
        const { user, recoveryCode, password } = resetPasswordData
        setLoading(true)
        try {
            return await Auth.ResetPassword(new ResetPasswordRequest(user, password, recoveryCode))
        } finally {
            setLoading(false)
        }
    }, [])

    const changePassword = useCallback(async (changePasswordData: ChangePasswordFormProps): Promise<EmptyResponse> => {
        const { user, password, passwordNew, passwordNewConfirm, code } = changePasswordData
        setLoading(true)
        try {
            return await Auth.ChangePassword(new ChangePasswordRequest(user, password, passwordNew, passwordNewConfirm, code))
        } finally {
            setLoading(false)
        }
    }, [])

    const forgotPassword = useCallback(async (forgotPasswordData: ForgotPasswordFormProps): Promise<EmptyResponse> => {
        setLoading(true)

        try {
            return await Auth.ForgotPassword(new UsernameAuthBaseRequest(forgotPasswordData.email))
        } finally {
            setLoading(false)
        }
    }, [])

    const storeUsername = useCallback(
        async (usernameNew: string) => {
            setUsername(usernameNew)
            setUserStored(rememberMe ? usernameNew : '')
        },
        [rememberMe, setUserStored]
    )

    const initializeRedirectToAppCount = useCallback(() => {
        setRedirectCount(0)
    }, [setRedirectCount])

    const incrementRedirectToAppCount = useCallback(() => {
        setRedirectCount(redirectToAppCount + 1)
    }, [redirectToAppCount, setRedirectCount])

    const storeRememberMe = useCallback(
        async (rememberMe: boolean) => {
            setUserStored(rememberMe ? username : '')
            setRememberMeStored(rememberMe)
        },
        [setRememberMeStored, setUserStored, username]
    )

    if (loadingEnvConfig !== ConfigStateEnum.Loaded) return <AuthContext.Provider value={AuthContextInitial} {...props} />

    const baseUrl = envConfig.AuthAPI.BaseUrl
    const credentials = envConfig.AuthAPI.CredentialsHeader

    if (!initialized && baseUrl !== '' && credentials !== '') {
        Auth.Start(baseUrl, credentials, envConfig.AuthAPI.requestTimeout)
        setInitialized(true)
        setLoading(false)
    }

    return (
        <AuthContext.Provider
            value={{
                username,
                rememberMe,
                loading,
                redirectToAppCount,
                signIn,
                getUser,
                newPassword,
                setup2FA,
                verify2FA,
                verifyAfterLogin2FA,
                createAccount,
                forgotPassword,
                resetPassword,
                changePassword,
                storeRememberMe,
                storeUsername,
                initializeRedirectToAppCount,
                incrementRedirectToAppCount,
            }}
            {...props}
        />
    )
}

const AuthContextInitial = { username: '', rememberMe: false, loading: true }

const AuthContext = createContext(AuthContextInitial)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useAuth = (): any => useContext(AuthContext)

export { AuthProvider, useAuth }
