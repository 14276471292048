import { v4 as uuidv4 } from 'uuid'
import axios, { AxiosInstance } from 'axios'
import {
    AuthResponse,
    ChangePasswordRequest,
    EmptyResponse,
    HttpErrorCodes,
    LoginRequest,
    NewPasswordRequest,
    ResetPasswordRequest,
    Setup2FAResponse,
    StartTokenRequest,
    UserResponse,
    UsernameAuthBaseRequest,
    Verify2FARequest,
} from './model'
import { ICreateAccountPropsBase } from '../../../components/forms/createAccountForm/Model'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Auth {
    public static baseUrl = ''
    public static apiSequence = ''
    public static sessionHeader = ''
    static axiosInstance: AxiosInstance

    public static Start(baseUrl: string, credentials: string, requestTimeout: number): void {
        Auth.baseUrl = baseUrl

        Auth.axiosInstance = axios.create({
            baseURL: baseUrl,
            timeout: requestTimeout,
        })

        Auth.axiosInstance.defaults.headers.common.Authorization = `Bearer ${credentials}`

        // Request Interceptor
        Auth.axiosInstance.interceptors.request.use(function (request) {
            request.headers.session = Auth.sessionHeader
            request.headers.apisequence = Auth.apiSequence
            return request
        })

        // Response Interceptor
        Auth.axiosInstance.interceptors.response.use(function (response) {
            // if the apisequence do not found use the previous one
            if (response.headers.apisequence != null) Auth.apiSequence = response.headers.apisequence
            // if the session do not found use the previous one
            if (response.headers.session != null) Auth.sessionHeader = response.headers.session

            Auth.ckeckHeaders()

            return response
        })

        Auth.StartToken()
    }

    private static ckeckHeaders(): void {
        if (Auth.apiSequence === '' || Auth.sessionHeader === '') {
            Auth.StartToken()
            throw new Error('Session or ApiSequence header values not found')
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static post<TReq>(endpoint: string, req: TReq, headers?: any): void {
        const url = `${Auth.baseUrl}/${endpoint}`
        Auth.axiosInstance
            .post(url, { ...req }, headers)
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .then((response) => {})
            .catch((err) => console.error(`post error '${url}' => ${JSON.stringify(err)} `))
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static async postAsync<TReq, TResp>(endpoint: string, req: TReq, headers?: any): Promise<TResp> {
        const url = `${Auth.baseUrl}/${endpoint}`
        const response = await Auth.axiosInstance.post(url, { ...req }, headers).catch((err) => {
            console.error(`postAsync error: '${url}' => ${JSON.stringify(err)} `)
            err.response.data.code = err.response.status
            return err.response
        })
        if (response == null) throw new Error(`postAsync error '${url}'`)
        if (response.data.code === undefined) response.data.code = HttpErrorCodes.OK
        return response.data
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static async getAsync<TQueryString, TResp>(endpoint: string, queryStringObj: TQueryString): Promise<TResp> {
        const queryStringRecord = queryStringObj as Record<string, string>
        const queryString = new URLSearchParams(queryStringRecord).toString()
        const url = `${Auth.baseUrl}/${endpoint}?${queryString}`
        const response = await Auth.axiosInstance.get(url).catch((err) => {
            console.error(`getAsync error: '${url}' => ${JSON.stringify(err)} `)
            err.response.data.code = err.response.status
            return err.response
        })
        if (response == null) throw new Error(`getAsync error '${url}'`)
        if (response.data.code === undefined) response.data.code = HttpErrorCodes.OK
        return response.data
    }

    public static StartToken(): void {
        const req = new StartTokenRequest(uuidv4())
        return Auth.post<StartTokenRequest>('startToken', req)
    }

    public static async GetUser(queryStringReq: UsernameAuthBaseRequest): Promise<UserResponse> {
        return await Auth.getAsync<UsernameAuthBaseRequest, UserResponse>('user', queryStringReq)
    }

    public static async Register(req: ICreateAccountPropsBase): Promise<EmptyResponse> {
        return await Auth.postAsync<ICreateAccountPropsBase, EmptyResponse>('register', req)
    }

    public static async Login(req: LoginRequest): Promise<AuthResponse> {
        return await Auth.postAsync<LoginRequest, AuthResponse>('login', req, { withCredentials: true })
    }

    public static async NewPassword(req: NewPasswordRequest): Promise<EmptyResponse> {
        return await Auth.postAsync<NewPasswordRequest, EmptyResponse>('newPassword', req)
    }

    public static async Setup2FA(req: UsernameAuthBaseRequest): Promise<Setup2FAResponse> {
        return await Auth.postAsync<UsernameAuthBaseRequest, Setup2FAResponse>('mfa/setup', req)
    }

    public static async Verify2FA(req: Verify2FARequest): Promise<EmptyResponse> {
        return await Auth.postAsync<Verify2FARequest, EmptyResponse>('mfa/verify', req, {
            withCredentials: true,
        })
    }

    public static async VerifyAfterLogin2FA(req: Verify2FARequest): Promise<EmptyResponse> {
        return await Auth.postAsync<Verify2FARequest, EmptyResponse>('login/mfa', req, {
            withCredentials: true,
        })
    }

    public static async ForgotPassword(req: UsernameAuthBaseRequest): Promise<EmptyResponse> {
        return await Auth.postAsync<UsernameAuthBaseRequest, EmptyResponse>('forgotPassword', req)
    }

    public static async ChangePassword(req: ChangePasswordRequest): Promise<EmptyResponse> {
        return await Auth.postAsync<UsernameAuthBaseRequest, EmptyResponse>('changePassword', req)
    }

    public static async ResetPassword(req: ResetPasswordRequest): Promise<EmptyResponse> {
        return await Auth.postAsync<UsernameAuthBaseRequest, EmptyResponse>('forgotPassword/submit', req)
    }
}
