import { PublicContentVaultLink, PublicContentK33, PublicContentCrossx, PublicContentCognitoDev, PublicContentLMOTCDemo } from './Firms'

const PublicContentFirmMapper: Record<string, () => JSX.Element> = {
    VaultLink: PublicContentVaultLink,
    K33: PublicContentK33,
    Crossx: PublicContentCrossx,
    CognitoDev: PublicContentCognitoDev,
    LMOTCDemo: PublicContentLMOTCDemo,
}

export default PublicContentFirmMapper
