import Form, { ButtonItem, ButtonOptions, Item, Label, RequiredRule } from 'devextreme-react/form'
import { useCallback } from 'react'
import { ICreateAccountPropsBase } from '../Model'

const AnticipatedActivityForm = (props: ICreateAccountPropsBase): JSX.Element => {
    const formData = props
    const allowEdition: boolean = props.allowEdition
    const formDisabled = !allowEdition

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            e.preventDefault()
            props.nextTab()
        },
        [props]
    )

    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData} colCount={2}>
                <Item dataField={'digitalAssetsDetails[4].response'} editorType={'dxSelectBox'} editorOptions={depositsPerMonthEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="option is required" />
                    <Label visible={false} />
                </Item>
                <Item dataField={'digitalAssetsDetails[5].response'} editorType={'dxSelectBox'} editorOptions={depositsInUsdEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Option is required" />
                    <Label visible={false} />
                </Item>
                <Item dataField={'digitalAssetsDetails[6].response'} editorType={'dxTagBox'} editorOptions={sourceEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Option is required" />
                    <Label visible={false} />
                </Item>
                <Item dataField={'digitalAssetsDetails[7].response'} editorType={'dxSelectBox'} editorOptions={withdrawalsInUsdEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="option is required" />
                    <Label visible={false} />
                </Item>
                <Item dataField={'digitalAssetsDetails[8].response'} editorType={'dxSelectBox'} editorOptions={withdrawalsPerMonthEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Option is required" />
                    <Label visible={false} />
                </Item>
                {/* Empty item placed here so the form can display properly in 2 columns */}
                <Item></Item>
                {!formDisabled ? (
                    <ButtonItem colSpan={2}>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">Continue</span>
                        </ButtonOptions>
                    </ButtonItem>
                ) : (
                    <></>
                )}
            </Form>
        </form>
    )
}

const depositsPerMonthEditorOptions = {
    stylingMode: 'outlined',
    placeholder: 'Anticipated deposits per month valued in USD?',
    mode: 'text',
    dataSource: ['Less than $100,000', '$100,000 - $999,999', '$1,000,000 - $10,000,000', 'Greater than $10,000,000'],
}
const depositsInUsdEditorOptions = {
    stylingMode: 'outlined',
    placeholder: 'Anticipated number of deposits per month?',
    mode: 'text',
    dataSource: ['Less than 25', '25 - 100', 'Greater than 100'],
}
const sourceEditorOptions = {
    stylingMode: 'outlined',
    placeholder: 'Source of anticipated deposits?',
    mode: 'text',
    dataSource: ['Credit/Loan', 'Crypto Mining/Staking', 'Gift', 'Inheritance', 'Initial Coin Offering (ICO)', 'Investments', 'Occupation', 'Savings'],
}
const withdrawalsInUsdEditorOptions = {
    stylingMode: 'outlined',
    placeholder: 'Anticipated number of withdrawals per month in USD?',
    mode: 'text',
    dataSource: ['Less than $100,000', '$100,000 - $999,999', '$1,000,000 - $10,000,000', 'Greater than $10,000,000'],
}
const withdrawalsPerMonthEditorOptions = {
    stylingMode: 'outlined',
    placeholder: 'Anticipated number of withdrawals per month?',
    mode: 'text',
    dataSource: ['Less than 25', '25 - 100', 'Greater than 100'],
}
const submitButtonAttributes = { class: 'submit-button' }

export default AnticipatedActivityForm
