import Form, { ButtonItem, ButtonOptions, Item, GroupItem } from 'devextreme-react/form'
import { useCallback, useState } from 'react'
import { ICreateAccountPropsBase, IQuestion, Question } from '../Model'
import { CheckBox, DataGrid } from 'devextreme-react'
import { Column, Editing, Lookup, Paging, RequiredRule } from 'devextreme-react/data-grid'
import { ColumnEditCellTemplateData, EditCanceledEvent, SavedEvent } from 'devextreme/ui/data_grid'
import { useCallbackWrapper } from '../../../../utils/useCallbackWrapper'
import { FileUploaderEditor, cellRender } from '../../../common'
import { useConfig } from '../../../../contexts'

const ExchangeWalletsForm = (props: ICreateAccountPropsBase): JSX.Element => {
    const formData = props
    const { commonConfig } = useConfig()
    const allowEdition: boolean = props.allowEdition
    const formDisabled = !allowEdition
    const [showExchangeWallet, setShowExchangeWallet] = useState(false)
    const customContent = commonConfig.Content.SignUp
    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            e.preventDefault()
            if (ews !== undefined && ews.length > 0) {
                ews.forEach((ew) => {
                    const tempArray: IQuestion[] = []
                    tempArray?.push(
                        new Question(4, 1, ew.ExchangeName),
                        new Question(4, 2, ew.DigitalAssetWallet),
                        new Question(4, 3, ew.BlockchainAddress),
                        new Question(4, 4, ew.Purchased),
                        new Question(4, 5, ew.Received),
                        new Question(4, 6, ew.Funding)
                        // TODO: add upload file GUID as value inside wallet
                        // new Question(4, 7, ew.Upload)
                    )
                    const exists = formData.wallets?.some((arr) => arr.every((obj) => tempArray.some((item) => JSON.stringify(item) === JSON.stringify(obj))))

                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    if (!exists) {
                        formData.wallets?.push([...tempArray])
                    }
                })
            }
            props.nextTab()
        },
        [props, formData.wallets]
    )

    const showExchangeWalletChanged = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            setShowExchangeWallet(e.value)
        },
        []
    )

    const options = [
        {
            ID: '1',
            Name: 'Yes',
        },
        {
            ID: '2',
            Name: 'No',
        },
    ]
    const [retryButtonVisible, setRetryButtonVisible] = useState(false)

    const onEditCanceled = useCallbackWrapper((e: EditCanceledEvent) => {
        if (retryButtonVisible) setRetryButtonVisible(false)
    })
    const fundingEditorOptions = {
        stylingMode: 'outlined',
        labelTemplateText: 'Will you be funding your account from the exchange wallet?',
        mode: 'text',
        icon: false,
        id: 'funding',
    }
    const onSaved = useCallbackWrapper((e: SavedEvent) => {
        if (retryButtonVisible) setRetryButtonVisible(false)
    })
    const editCellRender = useCallbackWrapper((cellInfo: ColumnEditCellTemplateData) => (
        <div className={'uploadContainer'}>
            <FileUploaderEditor cellInfo={cellInfo} retryButtonVisible={retryButtonVisible} />
        </div>
    ))
    const overrideOnValueChanged = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (e.dataField === 'Funding' && e.parentType === 'dataRow') {
                const defaultValueChangeHandler = e.editorOptions.onValueChanged
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                e.editorOptions.onValueChanged = function (args: any) {
                    // Override the default handler
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const containerFunding = document.querySelectorAll("input[id$='_Funding']")[0]!.parentElement!.parentElement!.parentElement!
                        .firstElementChild as HTMLInputElement
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const containerUpload = document.querySelector('.uploadContainer')!
                    containerUpload.setAttribute('style', 'visibility: ' + (containerFunding.value === '1' ? 'visible;' : 'hidden;'))
                    containerUpload.parentElement?.previousElementSibling?.setAttribute('style', 'visibility: ' + (containerFunding.value === '1' ? 'visible;' : 'hidden;'))
                    defaultValueChangeHandler(args)
                }
            }
        },
        []
    )

    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData}>
                <GroupItem>
                    <Item>
                        <div className="policy-info">
                            Wallet Address disclosure is solely used for purposes of assessing source of funds and risk scoring. We do not share or disclose any information listed.
                            This is captured solely for onboarding / depositing / withdrawing purposes.
                        </div>
                    </Item>
                    <GroupItem>
                        <CheckBox
                            value={showExchangeWallet}
                            onValueChanged={showExchangeWalletChanged}
                            text="Will you be funding your account with any exchange wallets?"
                            disabled={formDisabled}
                        />
                    </GroupItem>
                </GroupItem>
                {(showExchangeWallet || formDisabled) && (
                    <GroupItem caption={'Digital Asset Wallet'}>
                        <DataGrid
                            dataSource={ews}
                            keyExpr="ID"
                            showBorders={true}
                            disabled={formDisabled}
                            onEditCanceled={onEditCanceled}
                            onSaved={onSaved}
                            onEditorPreparing={overrideOnValueChanged}
                        >
                            <Paging enabled={false} />
                            <Editing mode="popup" allowUpdating={true} allowAdding={true} allowDeleting={true}>
                                <Item dataField={'ExchangeName'} />
                                <Item dataField={'DigitalAssetWallet'} />
                                <Item dataField={'BlockchainAddress'} />
                                <Item dataField={'Purchased'} />
                                <Item dataField={'Received'} />
                                <Item dataField={'Funding'} />
                                <Item itemType="group" colCount={2} colSpan={2}>
                                    <Item dataField={'Upload'} colSpan={2} />
                                </Item>
                            </Editing>
                            <Column dataField={'ExchangeName'} caption="Exchange Name" editorOptions={exchangeNameEditorOptions}>
                                <RequiredRule />
                            </Column>
                            <Column dataField={'DigitalAssetWallet'} caption="Digital Asset Wallet" editorOptions={digitalAssetWalletEditorOptions}>
                                <Lookup dataSource={customContent.assets} displayExpr="Name" valueExpr="ID" />
                                <RequiredRule />
                            </Column>
                            <Column dataField={'BlockchainAddress'} caption="Blockchain Address" editorOptions={blockchainAddressEditorOptions}>
                                <RequiredRule />
                            </Column>
                            <Column dataField={'Purchased'} caption="Have you purchased digital assets within the exchange disclosed?" editorOptions={purchasedEditorOptions}>
                                <Lookup dataSource={options} valueExpr="ID" displayExpr="Name" />
                                <RequiredRule />
                            </Column>
                            <Column dataField={'Received'} caption="Have you received digital assets to the exchanged disclosed?" editorOptions={receivedEditorOptions}>
                                <Lookup dataSource={options} valueExpr="ID" displayExpr="Name" />
                                <RequiredRule />
                            </Column>
                            <Column dataField={'Funding'} caption="Will you be funding your account from the exchange wallet?" editorOptions={fundingEditorOptions}>
                                <Lookup dataSource={options} valueExpr="ID" displayExpr="Name" />
                            </Column>
                            <Column
                                dataField={'Upload'}
                                width={70}
                                allowSorting={false}
                                cellRender={cellRender}
                                editCellRender={editCellRender}
                                caption="Please upload exchange account transaction statements"
                            />
                        </DataGrid>
                    </GroupItem>
                )}
                {!formDisabled && (
                    <ButtonItem>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">Continue</span>
                        </ButtonOptions>
                    </ButtonItem>
                )}
            </Form>
        </form>
    )
}

const exchangeNameEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Exchange Name',
    mode: 'text',
    icon: false,
    id: 'exchange_name',
}
const digitalAssetWalletEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Digital Asset Wallet',
    mode: 'text',
    icon: false,
    id: 'daw',
}
const blockchainAddressEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Blockchain Address',
    mode: 'text',
    icon: false,
    id: 'blockchain_address',
}
const purchasedEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Have you purchased digital assets within the exchange disclosed?',
    mode: 'text',
    icon: false,
    id: 'purchased',
}
const receivedEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Have you received digital assets to the exchanged disclosed?',
    mode: 'text',
    icon: false,
    id: 'received',
}

class EW {
    constructor(
        public ID: number,
        public ExchangeName: string,
        public DigitalAssetWallet: string,
        public BlockchainAddress: string,
        public Purchased: string,
        public Received: string,
        public Funding: string,
        public Upload: string
    ) {}
}
const ews: EW[] = []
const submitButtonAttributes = { class: 'submit-button' }

export default ExchangeWalletsForm
