import React, { useRef } from 'react'
import FileUploader from 'devextreme-react/file-uploader'
import { FileUploaderWithPreview } from './FileUploaderWithPreview'
import { ColumnEditCellTemplateData, ColumnCellTemplateData } from 'devextreme/ui/data_grid'

interface FileUploaderEditorProps {
    cellInfo: ColumnEditCellTemplateData
    retryButtonVisible: boolean
}
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const cellRender = (data: ColumnCellTemplateData): any => {
    if (data.value !== undefined) {
        return <p>{data.value}</p>
    } else {
        return <p>No image added</p>
    }
}

export const FileUploaderEditor = React.memo(({ cellInfo }: FileUploaderEditorProps) => {
    const fileUploaderRef = useRef<FileUploader>(null)

    return (
        <>
            <FileUploaderWithPreview cellInfo={cellInfo} fileUploaderRef={fileUploaderRef} />
        </>
    )
})
FileUploaderEditor.displayName = 'FileUploadEditor'
