import { useRef, useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule, PatternRule, CompareRule } from 'devextreme-react/form'
import { useAuth, useConfig } from '../../../contexts'
import notify from 'devextreme/ui/notify'
import NewPasswordFormProps from './Model'
import { ErrorMessage, Loading, SingleCard } from '../../common'
import '../form.scss'
import { HttpErrorCodes } from '../../../clients/api/auth/model'

const NewPasswordForm = (): JSX.Element => {
    const navigate = useNavigate()
    const { commonConfig } = useConfig()
    const [errorMessage, setErrorMessage] = useState('')
    const { newPassword, username, loading } = useAuth()

    const customContent = commonConfig.Content.NewPassword
    const customContentCommon = commonConfig.Content.Common
    const userLabel = customContent.userInputText
    const props = new NewPasswordFormProps(username)
    const formData = useRef({ ...props })

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (e: any): Promise<void> => {
            e.preventDefault()
            try {
                setErrorMessage('')
                const response = await newPassword(formData.current.username, formData.current.passwordNew, formData.current.passwordNewConfirm)
                if (response == null || response.code !== HttpErrorCodes.OK) {
                    setErrorMessage(`New Password Error: ${response?.message}`)
                    return
                }
                notify('New Password created successfully', 'success', 2000)
                navigate('/')
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                const msg = error.response?.data.error == null ? 'New Password Form Error' : error.response.data.error.message
                notify(msg, 'error', 2000)
            }
        },
        [newPassword, navigate]
    )
    const usernameEditorOptions = {
        stylingMode: 'outlined',
        label: userLabel,
        labelMode: 'floating',
        mode: 'text',
    }
    const passwordNewEditorOptions = {
        stylingMode: 'outlined',
        label: 'New Password',
        mode: 'password',
        labelMode: 'floating',
    }
    const passwordNewRepeatEditorOptions = {
        stylingMode: 'outlined',
        label: 'Confirm New Password',
        mode: 'password',
        labelMode: 'floating',
    }
    const submitButtonAttributes = { class: 'submit-button' }
    return (
        <SingleCard {...customContent}>
            <form className={'forgot-password-form'} onSubmit={onSubmit}>
                <Form formData={formData.current} disabled={loading}>
                    <Item dataField={'username'} editorType={'dxTextBox'} editorOptions={usernameEditorOptions}>
                        <RequiredRule message="Username is required" />
                        <Label visible={false} />
                    </Item>
                    <Item dataField={'passwordNew'} editorType={'dxTextBox'} editorOptions={passwordNewEditorOptions}>
                        <RequiredRule />
                        <PatternRule
                            pattern="^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                            message="At least 8 characters, 1 number, 1 uppercase character, 1 of the following !@#$%^&*"
                        />
                        <Label visible={false} />
                    </Item>
                    <Item dataField={'passwordNewConfirm'} editorType={'dxTextBox'} editorOptions={passwordNewRepeatEditorOptions}>
                        <RequiredRule message="Password is required" />
                        <CompareRule message={'Passwords do not match'} comparisonType={'=='} comparisonTarget={() => formData.current.passwordNew} />
                        <Label visible={false} />
                    </Item>
                    <ButtonItem>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">{loading === true ? <Loading /> : customContent.submitButtonText}</span>
                        </ButtonOptions>
                    </ButtonItem>
                    {errorMessage !== '' ? (
                        <Item>
                            <ErrorMessage message={errorMessage} />
                        </Item>
                    ) : null}
                    <Item>
                        <div className={'login-link'}>
                            {customContentCommon.backToLoginPrefix}
                            <Link to={'/'}> {customContentCommon.backToLoginLinkText}</Link>
                        </div>
                    </Item>
                </Form>
            </form>
        </SingleCard>
    )
}

export default NewPasswordForm
